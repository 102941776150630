<template>
    <div class="left overflow-y-auto pr-4" style="min-height: 815px;">
        <div
            style="padding-left: 18px;"
            :style="{height: getLeftLevelHeight(firstLevel)}"
            v-for="(firstLevel, i) in systemData"
            :key="firstLevel.id"
            class="overflow-y-scroll py-3 border-white border-b-2"
            :class="i == 0 && 'border-t-2'"
        >
            <h1 class="text-primary-one fw-800 fs-14 mb-3">
                {{ firstLevel.index_id }}{{ firstLevel.index_code }} {{ firstLevel.title }}
            </h1>
          <div v-for="(level, index) in firstLevel.children" :key="index">
            <LevelData
                :switchSidebarComponent="switchSidebarComponent"
                :sidebarName="sidebarName"
                :level="level"
                :systemDates="systemDates"
                color="#2BAD7E"
            />
          </div>
        </div>
    </div>
</template>

<script setup>
import LevelData from '@/components/roadmap/strategicRoadmap/components/LavelData.vue'
import useHighlevelChart from '@/components/roadmap/strategicRoadmap/highLevelData/useHighlevelChart.js'

const props = defineProps({
    systemData: Array,
    switchSidebarComponent: Function,
    sidebarName: String,
    systemDates: Object,
})
const { getLeftLevelHeight } = useHighlevelChart()
</script>

<style scoped>
.left{
   width: 317px;
   min-width: 317px;
}
</style>
