import { dateDefer } from '@/plugins/lodash.js'
import { format as dateFormat, addDays } from 'date-fns'
import { getParameterValue } from '@/store/modules/roadmap/services'
import { ref } from 'vue';
import axios from 'axios'

let isChangeParameter = ref(false)

export default function useHighlevelChart() {
    const divTopPadding = 20 //in px
    const itemGap = 12 //in px
    const itemHeight = 25 //in px

    const getLevelHeight = () => {
        return '100px'
    }

    const getChildCount = (item) => {
        if (item.children.length == 0) return 0
        let data = []
        data = [{ children: item.children }]
        const countChildren = obj => obj.children.length + obj.children.reduce((acc, c) => acc + countChildren(c), 0);
        return data.reduce((acc, c) => acc + countChildren(c), 0)
    }

    //have to refactor this
    const getGridBoxWidth = (items) => {
        if (!items) return 0
        let child = 0
        for (let i = 0; i < items.length; i++) {
            child += getChildCount(items[i])
        }

        return {
            height: `${child * (itemHeight + 60) + divTopPadding}px`
        }
    }

    const getLeftLevelHeight = (item) => {
        let child =  getChildCount(item) + 1
        let height = child * (itemHeight + itemGap) + divTopPadding
        return height < 65 ? '65px' : height + 'px'
        // return height < 200 ? '200px' : height + 'px'
    }

    const getPhaseWidth = () => {
        const timelineParent = document.getElementById('timelineParent')
        if (!timelineParent) return 0
        return timelineParent.offsetWidth
    }

    const datePerPixel = (dates) => getPhaseWidth() / dates

    const dateToPixel = (timelineTotalDate, dates) => datePerPixel(timelineTotalDate) * dates

    const getBarStyle = (level, strategy, systemDates, hasChildren) => {
        const levelStartDate = level.start_date || systemDates.item_start_date
        const levelEndDate = _getLevelEndDate(levelStartDate, level.end_date)

        if (level.children.length <= 0) {
            if (level.end_date) {
                if (new Date(level.end_date).getTime() !== new Date(levelEndDate).getTime()) {
                    _updateLevelData(level, {end_date: levelEndDate})
                }
            } else {
                _updateLevelData(level, {end_date: levelEndDate})
            }
        }

        level.start_date = dateFormat(new Date(levelStartDate), 'yyyy-MM-dd')
        level.end_date = dateFormat(new Date(levelEndDate), 'yyyy-MM-dd')

        const { phase_start_date, phase_end_date } = systemDates
        const phaseTotalDate = dateDefer(phase_start_date, phase_end_date)
        const levelTotalDate = dateDefer(levelStartDate, levelEndDate)
        const levelWidthPercentage = _getPercentage(phaseTotalDate, levelTotalDate)
        // diff between phase and level start date
        const startDifference = dateDefer(levelStartDate, phase_start_date)
        const marginLeftInPercentage = _getMarginLeft(strategy, level, startDifference, phaseTotalDate)

        if (hasChildren) {
            return _getWidthMarginStyle(levelWidthPercentage, marginLeftInPercentage)
        }
        return {
            width: dateToPixel(phaseTotalDate, levelTotalDate) || 1,
            left: dateToPixel(phaseTotalDate, startDifference)
        }
    }

    const _getEndDate = (startDate) => {
        let newStartDate = new Date(startDate)
        let newEndDate = newStartDate.setDate(newStartDate.getDate() + 30)
        return dateFormat(newEndDate, 'yyyy-MM-dd')
    }

    const onResizing = ({ left, top, width, height, handle, level, systemDates }) => {//{left, width, oldLeft}
        const { phase_start_date, phase_end_date } = systemDates
        const phaseTotalDate = dateDefer(phase_start_date, phase_end_date)

        if(handle == 'ml') {
            const totalDateFromLeft = left / datePerPixel(phaseTotalDate)
            level.start_date = dateFormat(addDays(new Date(phase_start_date), totalDateFromLeft), 'yyyy-MM-dd')
            _updateLevelData(level, {start_date: level.start_date})
        }

        if(handle == 'mr') {
            const totalDateForWidth = width / datePerPixel(phaseTotalDate)
            level.end_date = dateFormat(addDays(new Date(level.start_date), totalDateForWidth), 'yyyy-MM-dd')
            _updateLevelData(level, {end_date: level.end_date})
        }
    }

    const _getLevelEndDate = (startDate, endDate) => {
        if (endDate) {
            if (new Date(startDate) > new Date(endDate)) {
                return _getEndDate(startDate)
            }
            return endDate
        }
        return _getEndDate(startDate)
    }

    const _getMarginLeft = (strategy, level, startDifference, phaseTotalDate) => {
        if (strategy.parameters.length
            && strategy.parameters[0].id
            && strategy.parameters[0].property_id) {
            const parameterValue = getParameterValue(level, strategy.parameters[0])
            return parameterValue * 10
        }

        return _getPercentage(phaseTotalDate, startDifference)
    }

    const _getPercentage = (total, value) => (100 / total) * value

    const _getWidthMarginStyle = (width, margin) => {
        return {
            marginLeft: margin + '%',
            width: width + '%'
        }
    }

    const _updateLevelData = (item, params) => {
        axios.post(`projects/scenarios/${item.scenario_id}/update/${item.id}`, {
            ...params,
            id: item.id,
            title: item.title
        })
    }

    return {
        getLevelHeight,
        getChildCount,
        onResizing,
        getBarStyle,
        getLeftLevelHeight,
        getGridBoxWidth,
        isChangeParameter
    }
}
