<template>
    <div  class="space-y-3">
        <div style="height: 25px;"  v-if="level.children <= 0" class="relative z-20">
            <vue-draggable-resizable
                class="panzoom-exclude"
                :active="false"
                :handles="['ml','mr']"
                :w="barStyle.width"
                :h="25"
                :x="barStyle.left"
                
                axis="x"
                :draggable="false"
                ref="element"
                @resizestop="(left, top, width, height, handle) =>onResizing({left, top, width, height, handle, level, systemDates})"
            >
                <div
                    :key="level.id"
                    class="px-2 w-full flex items-center text-white fw-600 fs-12 i-h-25 grid-shadow"
                    :style="{backgroundColor: level.background_color}"
                    :title="level.index_code+level.title"
                >
                    <p class="truncate w-full px-2">{{ level.index_id }} {{ level.index_code }}</p>

                </div>
            </vue-draggable-resizable>
        </div>
        <div
            v-else
            :key="level.id"
            class="px-2 flex items-center text-white fw-600 fs-12 i-h-25 grid-shadow relative z-20"
            :style="{backgroundColor: level.background_color, ...barStyle}"
            :title="level.index_code+level.title"
        >
            <p class="truncate w-full">{{ level.index_id }} {{ level.index_code }}</p>

        </div>
        <GirdItem
            v-for="item in level.children"
            :key="item.id"
            :level="item"
            :childCount="childCount+1"
            :getBarStyle="getBarStyle"
            :strategy="strategy"
            :systemDates="systemDates"
            :onResizing="onResizing"
        />
    </div>
</template>

<script>
import {random} from 'lodash'
import VueDraggableResizable from '@/plugins/vue-draggable-resizable/Index'
import '@/assets/css/vue-draggable-resizable-strategy.css'

export default{
    name: 'GirdItem',
    components: {VueDraggableResizable},
    props: {
        level: Object,
        childCount: {
            type: [String, Number],
            default: 0
        },
        getBarStyle: Function,
        onResizing: Function,
        strategy: Object,
        systemDates: Object

    },
    computed: {
        barStyle() {
            return  this.getBarStyle(this.level, this.strategy, this.systemDates, this.level.children.length > 0)
        }
    },
    methods: {
        random: random
    }
}
</script>
<style>
.grid-shadow {
  box-shadow: 0px 1px 6px rgba(44, 52, 65, 0.1);
}
</style>
