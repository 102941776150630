<template>
    <BaseLayout
        :sidebarVisibility="sidebarVisibility"
        @exitFullScreen="sidebarVisibility = true"
    >
        <template #sidebar>
            <Sidebar
                :sidebarItems="sidebarItems"
                :switchSidebarComponent="switchSidebarComponent"
            >
                <component
                    v-bind="sidebarProps"
                    :is="activeSidebarComponent"
                    :project="project"
                />
            </Sidebar>
        </template>

        <div class="header-block">
            <Header
                :configuration="strategy"
                :scenarios="scenarios"
                @onScenarioChange="handleScenarioChange"
            />
            <div class="flex justify-between w-full">
                <div class="flex items-center gap-x-4">
                    <input
                        style="width: 302px;"
                        class="bg-transparent fs-24 fw-700 h-10 pr-2"
                        type="text"
                        readonly
                        v-model="strategy.title"
                        @input="updateStrategy(strategy)"
                    >
                    <Toolbar style="margin-bottom: 4px; margin-right: -6px;" />
                    <StyleAndFilter style="margin-bottom: 4px;" />
                    <Settings
                        v-if="strategy.parameters && strategy.parameters.length"
                        :configuration="strategy.parameters[0]"
                        :settings="strategy.settings"
                        :parameters="parameters"
                        :updateSync="updateSync"
                        @onSelectParameter="handleParameterChange"
                        class="ml-8"
                    />
                    <button @click="handleApplyToTimeline" class="fs-14 fw-800 italic text-primary-one opacity-40">
                        + apply to timeline
                    </button>
                </div>
            </div>
            <GraphWrapper>
                <Top
                    :configuration="strategy"
                    :modules="modules"
                    :phases="systemData.phases"
                    @onModuleChange="handleModuleChange"
                    style="margin-top: 5px;margin-bottom: 12px;"
                />
                <template #content>
                    <div class="flex w-full">
                        <LeftSide
                            :systemData="systemData.children"
                            :systemDates="systemData.dates"
                            :switchSidebarComponent="switchSidebarComponent"
                            sidebarName="Properties"
                        />
                        <div style="width: 1155px">
                            <ChartGrid
                                :systemData="systemData.children"
                                :strategy="strategy"
                                :systemDates="systemData.dates"
                            />
                        </div>
                    </div>
                </template>
            </GraphWrapper>
        </div>
    </BaseLayout>
</template>

<script setup>
import { computed, ref } from 'vue';
import router from '@/routes/routes.js'
import { useSidebar } from './useSidebar';
import useHighLevelData from './useHighLevelData.js';
import { BaseLayout, Sidebar, Header, Toolbar, GraphWrapper, StyleAndFilter, Settings, ChartGrid } from './useImporter';
import store from "@/store"
import { isEmpty, get } from 'lodash'
import axios from 'axios'

//refactor
import LeftSide from '@/components/roadmap/strategicRoadmap/highLevelData/LeftSide.vue';
import Top from '@/components/roadmap/strategicRoadmap/components/Top.vue';

const route = router.currentRoute;
const {
    strategy,
    getStrategy,
    scenarios,
    modules,
    module,
    handleModuleChange,
    systemData,
    handleScenarioChange,
    parameters,
    updateStrategy,
    updateSync,
    setModuleId,
    setSystemData,
    handleParameterChange,
} = useHighLevelData()

const {
    switchSidebarComponent,
    activeSidebarComponent,
    currentSidebar,
    propsData,
    sidebarVisibility,
    sidebarItems
} = useSidebar();

const lastLevelDataIds = ref([])
const sidebarPropsDataSet = ref({
    'ModuleList': {
        modules,
        module: module,
        setModuleId
    },
    'Properties': {
        strategy,
        module,
        properties: parameters,
        project: {},
        setSystemData
    }
});

const project = computed(() => {
  return store.getters["project/project"]
})

const sidebarProps = computed(() =>  {
    return {
        ...sidebarPropsDataSet.value[currentSidebar.value],
        ...propsData.value
    }
})

const handleApplyToTimeline = async () => 
{
    _getLastLevelData(systemData.value.children)

    if(
        !strategy.value.parameters[0].id 
        || isEmpty(lastLevelDataIds.value) 
        || !get(systemData.value, 'dates.phase_start_date')
        || !get(systemData.value, 'dates.phase_end_date')
    ){
        alert('Failed to apply')
        return
    }

    const payload = {
        module_id: strategy.value.module_id,
        scenario_id: strategy.value.scenario_id,
        phase_start_date: systemData.value.dates.phase_start_date,
        phase_end_date: systemData.value.dates.phase_end_date,
        parameter_id: strategy.value.parameters[0].id,
        collection_ids: JSON.stringify(lastLevelDataIds.value)
    }
    let response = await axios.post(`roadmaps/strategies/system-data/apply-to-timeline`, payload)
    if(response.data.status){
        getStrategy(route.params.id)
    }

}

const _getLastLevelData = (levelData) => {
    if(!levelData) return
    levelData.forEach(item => {
        if(!isEmpty(item.children)){
            _getLastLevelData(item.children)
            return
        }else{
            lastLevelDataIds.value.push(item.id)
        }
    })
}
</script>
<style scoped>
.header-block {
  margin-top: -5.2rem;
}
</style>
