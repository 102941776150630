<template>
    <div class="flex overflow-x-hidden h-full border-2 border-white w-full">
            <!-- <div class="i-w-50 bg-primary-three z-10">
                <div 
                    v-for="item in systemData" 
                    :key="item.id" 
                    class="py-2"
                    :style="{height: getLeftLevelHeight(item)}"
                >
                    <p
                        
                        :title="`${item.index_id}${item.index_code} ${item.title}`"
                        class="truncate rotate-180 flex vertical-lr text-center w-full items-center justify-center h-full">
                        {{ item.index_id }}{{ item.index_code }}
                    </p>
                </div>
            </div> -->
        <!-- :style="{...getGridBoxWidth(systemData)}" -->
        <div id="timelineParent"  class="relative w-full">
            <div style="column-gap: 1px;" class="flex h-full relative">
                <div 
                    v-for="n in 18"
                    :key="n"
                    class="grid-col relative border-r border-white z-10 pointer-events-none"
                ></div>
            </div>

            <!-- vertical lines/ -->
            <div 
                style="width:384px; left: 0;" 
                :style="{backgroundColor: strategy.settings?.colors ? strategy.settings?.colors[0] : ''}" 
                class="absolute opacity-30 border-r-2 border-white z-20 h-full top-0"
            ></div>
            <div 
                style="width:384px; left: 384px;" 
                :style="{backgroundColor: strategy.settings?.colors ? strategy.settings?.colors[1] : ''}" 
                class="absolute opacity-30 border-r-2 border-white z-20 h-full top-0"
            ></div>
            <div 
                style="width:384px; left: 768px;" 
                :style="{backgroundColor: strategy.settings?.colors ? strategy.settings?.colors[2] : ''}" 
                class="absolute opacity-30 border-r-2 border-white z-20 h-full top-0"
            ></div>
            <!-- vertical lines/ -->
            
            <div class="absolute top-0 w-full">
                <div 
                    style="min-height: 65px;" 
                    class="space-y-3 border-b-2 border-white py-4 z-10" 
                    v-for="(item, index) in systemData" 
                    :key="index"
                >
                    <GridItem
                        :level="item"
                        :getBarStyle="getBarStyle"
                        :strategy="strategy"
                        :systemDates="systemDates"
                        :onResizing="onResizing"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import useHighlevelChart from './useHighlevelChart.js'

import GridItem from './GridItem.vue';
const props = defineProps({
    systemData: Array,
    strategy: Object,
    systemDates: Object
})

const {
    getBarStyle,
    onResizing,
    getLeftLevelHeight
} = useHighlevelChart()
</script>

<style scoped>
.grid-col {
    width: 64.6px;
    height: calc(100% - 1px);
}
</style>
